import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowRight,
  ArrowForward,
  HeroButton,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!onHover);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted playsInline src={Video} type="video/mp4" />
      </HeroBg>
      {/* <HeroContent>
        <HeroH1>The Road to Better IT Starts Here</HeroH1>
        <HeroP>Press Start, to discover the possibilities.</HeroP>
        <HeroBtnWrapper>
          <HeroButton
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=G3e_v866EEKXlE9bGV1X7NT9_QW2X9NAkiwvXKOnHLtUMzMxNEE3TE8xRkRRMlBEU0RJRFk1SzFMSyQlQCN0PWcu"
            onMouseEnter={setHover}
            onMouseLeave={onHover}
            primary={true}
            dark={true}
            target="_blank"
          >
            START {hover ? <ArrowForward /> : <ArrowRight />}
          </HeroButton>
        </HeroBtnWrapper>
      </HeroContent> */}
    </HeroContainer>
  );
};

export default HeroSection;
